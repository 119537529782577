import { useContext } from "react";
import Logo from "../../../assets/Frame 3.svg";
import styles from "../../CSS/Layout.module.css";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";

export default function Header() {
  return (
    <header
      className={`borderBottom bgColorDefault ${styles.uploadHeader}`}
      role={"banner"}
    >
      <div>
        <img
          alt="logo"
          src={Logo}
          className={`${styles.headerIcon}`}
          aria-hidden="true"
        />
      </div>
      <div className={styles.headerText}>
        <div>
          <b data-cy={"self-upload-header-text"}>
            Content Generation System - Self Upload
          </b>
        </div>
      </div>
      <div>
        <label>&nbsp;</label>
      </div>
    </header>
  );
}
