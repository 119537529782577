import { useContext } from "react";
import styles from "../../CSS/Layout.module.css";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";

import Avatar from "../../../assets/whitelogo.svg";
import { urlTypeVal } from "../../../constants/constants";
export default function Header({ url }) {
  const appStateContext = useContext(AppStateContext);
  return (
    <header
      className={
        appStateContext?.state.isSmallScreen
          ? `borderBottom bgColorDefault row ${styles.headerSmallScreen} ${styles.headerShareSmallScreen} ${styles.headerShare} ${styles.height80}`
          : `borderBottom bgColorDefault row ${styles.headerShare} ${styles.height80}`
      }
      role={"banner"}
    >
      <div
        className={
          appStateContext?.state.isSmallScreen
            ? `col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xl-6 ${styles.headersubText}`
            : `col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xl-4 ${styles.headersubText}`
        }
      >
        {url !== urlTypeVal.rh && (
          <img
            alt="logo"
            src={Avatar}
            className={`${styles.headerIcon}`}
            aria-hidden="true"
          />
        )}
        &nbsp; &nbsp;
        <div>
          <b>Transaction Documents</b>
        </div>
      </div>
      <div
        className={
          appStateContext?.state.isSmallScreen
            ? `col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xl-6 ${styles.headersubText}`
            : `col-lg-8 col-md-8 col-sm-8 col-xs-8 col-xl-8 ${styles.headersubText}`
        }
      >
        <div>
          <b>
            Access your contracts, invoices and retirement certificates here
          </b>
        </div>
      </div>
    </header>
  );
}
