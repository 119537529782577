import { useState, useRef, useContext, useEffect } from "react";
import {
  Stack,
  CommandBarButton,
  ContextualMenu,
  DirectionalHint,
} from "@fluentui/react";
import { Snackbar, Alert } from "@mui/material";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import { pinUnpinConversation } from "../../../api";
import type { AlertColor } from "@mui/material";

initializeIcons();

const ItemActions = ({
  onEdit,
  toggleDeleteDialog,
  isPinned,
  isSelected,
  isHovered,
  item,
  setErrorRename,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const menuButtonRef = useRef(null); // ✅ Correct ref for targeting DOM element
  const [pinnedCount, setPinnedCount] = useState(0);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor;
  }>({
    open: false,
    message: "",
    severity: "info",
  });
  const appStateContext = useContext(AppStateContext);

  const toggleMenu = (e) => {
    e.stopPropagation();
    setMenuVisible((prev) => !prev);
  };
  useEffect(() => {
    const count = appStateContext.state.chatHistory.filter(
      (chat) => chat.pinned
    ).length;
    setPinnedCount(count);
  }, [appStateContext.state.chatHistory]);

  const togglePin = async (action) => {
    setInProcess(true);
    let response = await pinUnpinConversation({
      conversation_id: item.id,
      action: action,
    });

    if (response) {
      appStateContext?.dispatch({
        type: "SET_PIN_UNPIN_CHAT",
        payload: { id: item.id, action: action },
      });
    } else {
      setSnackbar({
        open: true,
        message: `Unable to Pin Conversation right now.`,
        severity: "error",
      });
    }

    setInProcess(false);
  };

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    setMenuItems([
      {
        key: "edit",
        text: "Edit",
        iconProps: {
          iconName: "Edit",
          styles: { root: { color: "var(--secondary)" } },
        },
        onClick: (e) => {
          e.stopPropagation();
          onEdit();
          setMenuVisible(false);
        },
      },
      {
        key: "delete",
        text: "Delete",
        iconProps: {
          iconName: "Delete",
          styles: { root: { color: "var(--secondary)" } },
        },
        onClick: (e) => {
          e.stopPropagation();
          toggleDeleteDialog();
          setMenuVisible(false);
        },
      },
      {
        key: "pin",
        text: item.pinned ? "Unpin" : "Pin",
        title: item.pinned
          ? "Unpin this conversation"
          : pinnedCount === 3
          ? "Maximum of 3 conversations can be pinned"
          : "Pin this conversation",
        iconProps: {
          iconName: item.pinned ? "PinSolidOff12" : "PinSolid12",
          styles: {
            root: {
              color:
                !item.pinned && pinnedCount === 3
                  ? "var(--disabled)"
                  : "var(--secondary)", // ✅ Change color to red when disabled
            },
          },
        },
        disabled: !item.pinned && pinnedCount === 3, // ✅ Dynamically disable the button
        onClick: async (e) => {
          e.stopPropagation();
          if (!(pinnedCount === 3 && !item.pinned)) {
            await togglePin(item.pinned ? "unpin" : "pin");
          }
          setMenuVisible(false);
        },
      },
    ]);
  }, [pinnedCount, item.pinned]); // ✅ Update when `pinnedCount` or `item.pinned` changes

  return (
    <div>
      <Stack horizontal horizontalAlign="end">
        <CommandBarButton
          title="More"
          onClick={toggleMenu}
          className="bgTransparent"
          iconProps={{ iconName: "More" }}
          styles={{ root: { padding: "0", backgroundColor: "transparent" } }}
          disabled={inProcess}
          elementRef={menuButtonRef} // ✅ Correct ref binding
        />
        <ContextualMenu
          items={menuItems}
          hidden={!menuVisible}
          onDismiss={() => setMenuVisible(false)}
          target={menuButtonRef.current} // ✅ Now correctly targeting the DOM element
          directionalHint={DirectionalHint.bottomLeftEdge} // ✅ Ensuring correct positioning
        />
      </Stack>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ItemActions;
