import Header from "../header/Header";
import styles from "../../CSS/Layout.module.css";
import { useContext, useEffect, useState } from "react";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import { urlTypeVal } from "../../../constants/constants";
import Transaction from "../../../components/Transaction/Transaction";
const Layout = ({ urlType }) => {
  return (
    <div>
      <div className={`${styles.LayoutShareChat} bgColorPrimary`}>
        <Header url={urlType} />
        <Transaction url={urlType} />
      </div>
    </div>
  );
};

export default Layout;
