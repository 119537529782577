/**
 * Fetches data for Reporting Hub authentication.
 */
import { Dispatch, useContext, useEffect } from "react";
import {
  UserLoginDetails,
  getRHAuthUrl,
  getRHUserName,
  getReportingHubUserEmail,
  logUserLoginDetails,
} from "../../../api";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import { urlTypeVal } from "../../../constants/constants";

export default function useReportingHubAuthentication(
  isRHEnabled: boolean,
  urlType
) {
  const appStateContext = useContext(AppStateContext);

  useEffect(() => {
    if (!isRHEnabled) return;
    const fetchData = async () => {
      // get reporting hub session data
      const RHUserName = sessionStorage.getItem("RHUserName");

      const currentPath = window.location.pathname.toLowerCase();
      const isTransactionRoute = currentPath.startsWith("/transaction");
      if (
        RHUserName !== null &&
        RHUserName !== "" &&
        RHUserName !== undefined
      ) {
        // if the user name is already present, no need to fetch the data again
        const userName = await getRHUserName();

        if (userName !== RHUserName) {
          // if the user name is different, fetch the data again
          console.log("user name is different");
          // remove session storage
          sessionStorage.removeItem("RHUserName");
          sessionStorage.removeItem("RHUserEmail");
          sessionStorage.setItem("postLoginRedirect", window.location.href);
          const RHauthurl = await getRHAuthUrl({
            istransaction_page:
              isTransactionRoute && urlType === urlTypeVal.rh
                ? "true"
                : "false",
          });
          window.location.href = RHauthurl;
        } else {
          // if the user name is same, set the authentication flag
          appStateContext?.dispatch({
            type: "SET_IS_AUTHENTICATED",
            payload: true,
          });
        }
      }
      // if the user name is not present, perform the authentication
      else {
        // check if the url contains the code which can be used to get the user email
        if (window.location.search.includes("code")) {
          const urlParams = new URLSearchParams(window.location.search);
          const authorizationCode = urlParams.get("code");
          console.log("authorizationCode", authorizationCode);
          if (authorizationCode !== "" && authorizationCode !== undefined) {
            getReportingHubUserEmail(authorizationCode || "").then(
              async (response) => {
                if (response) {
                  console.log("user email before processing:", response);

                  // Remove "live.com#" prefix if present
                  if (response.startsWith("live.com#")) {
                    response = response.replace(/^live\.com#/, "").trim();
                  }

                  console.log("user email after processing:", response);
                  sessionStorage.setItem("RHUserEmail", response);

                  const userName = await getRHUserName();
                  console.log("RHUserName", userName);
                  sessionStorage.setItem("RHUserName", userName);

                  // log the user login details
                  const userLoginDetails: UserLoginDetails = {
                    user_email: response || "",
                    user_name: userName,
                    provider: "ReportingHub",
                  };

                  logUserLoginDetails(userLoginDetails);

                  appStateContext?.dispatch({
                    type: "SET_IS_AUTHENTICATED",
                    payload: true,
                  });
                }
              }
            );
          }
        } else {
          // if the url does not contain the code, redirect to the reporting hub authentication url
          sessionStorage.setItem("postLoginRedirect", window.location.href);
          const RHauthurl = await getRHAuthUrl({
            istransaction_page:
              isTransactionRoute && urlType === urlTypeVal.rh
                ? "true"
                : "false",
          });
          console.log("RHauthurl", RHauthurl);
          window.location.href = RHauthurl;
        }
      }
    };
    fetchData();
  }, [isRHEnabled]);
}
