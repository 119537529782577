import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
import dayjs, { Dayjs } from "dayjs";
import type { AlertColor } from "@mui/material";

import {
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import { Button } from "react-bootstrap";
import styles from "./Transaction.module.css";
import {
  companyList,
  DownloadFile,
  FetchExternalTransactionDocuments,
} from "../../api";
import { urlTypeVal } from "../../constants/constants";

export default function Transaction({ url }) {
  const today = dayjs();
  const threeMonthsAgo = today.subtract(3, "month");
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [options, setOptions] = useState([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(threeMonthsAgo);
  const [endDate, setEndDate] = useState<Dayjs | null>(today);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState("");
  const [tokenAuth, setTokenAuth] = useState(true);
  const [invalidMsg, setInvalidMsg] = useState("");
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor;
  }>({
    open: false,
    message: "",
    severity: "info",
  });

  useEffect(() => {
    function fetchCompanies() {
      let payload = {};
      if (url === urlTypeVal.rh) {
        payload = {
          access_type: "external",
        };
      } else {
        payload = {
          access_type: "internal",
          encoded_token: new URL(window.location.href).searchParams.get(
            "token"
          ),
        };
      }
      companyList(payload)
        .then((response) => response.json())
        .then((jsonData) => {
          jsonData.data === undefined
            ? setOptions([])
            : setOptions(jsonData.data);
        })
        .catch((error) => {
          console.error("Failed to fetch companies", error);
          setOptions([]);
        });
    }

    if (url === urlTypeVal.rh || url === urlTypeVal.noRh) {
      fetchCompanies();
      fetchTableData();
    }
  }, [url]);

  const fetchTableData = async () => {
    setError("");
    setInvalidMsg("");
    setTokenAuth(true);
    setTableData([]);
    const todayDate = dayjs().startOf("day");
    const threeMonthsAgoDate = todayDate.subtract(3, "month").startOf("day");

    if (!startDate || !endDate) {
      setError("Start date and end date both are required.");
      return;
    }

    const normalizedStartDate = startDate.startOf("day");
    const normalizedEndDate = endDate.startOf("day");

    if (!normalizedStartDate.isValid() || !normalizedEndDate.isValid()) {
      setError("Start date or end date is invalid.");
      return;
    }

    if (normalizedStartDate.isAfter(normalizedEndDate)) {
      setError("Start date must be equal or earlier than the end date.");
      return;
    }

    if (
      normalizedStartDate.isAfter(todayDate) ||
      normalizedEndDate.isAfter(todayDate)
    ) {
      setError("Dates cannot be in the future.");
      return;
    }

    if (
      normalizedStartDate.isBefore(threeMonthsAgoDate) ||
      normalizedEndDate.isBefore(threeMonthsAgoDate)
    ) {
      setError("Dates cannot be earlier than three months ago.");
      return;
    }

    setFetching(true);

    let payload = {};
    if (url === urlTypeVal.rh) {
      payload = {
        account_id: selectedKeys,
        start_date: normalizedStartDate.format("YYYY-MM-DD"),
        end_date: normalizedEndDate.format("YYYY-MM-DD"),
        access_type: "external",
      };
    } else if (url === urlTypeVal.noRh) {
      const parsedUrl = new URL(window.location.href);
      const queryParams = parsedUrl.searchParams;
      payload = {
        account_id: selectedKeys,
        start_date: normalizedStartDate.format("YYYY-MM-DD"),
        end_date: normalizedEndDate.format("YYYY-MM-DD"),
        encoded_token: queryParams.get("token"),
        access_type: "internal",
      };
    } else {
      setTableData([]);
      setFetching(false);
      return;
    }

    FetchExternalTransactionDocuments(payload)
      .then((response) => response.json())
      .then((jsonData) => {
        if (jsonData.status_code === 401 || jsonData.status_code === 403) {
          setInvalidMsg(jsonData.message);
          setTokenAuth(false);
        } else {
          setTableData(jsonData.documents || []);
        }
        setFetching(false);
      })
      .catch((error) => {
        console.error("Error fetching data", error);
        setTableData([]);
        setFetching(false);
        setInvalidMsg(
          "You are not authenticated. Please contact your SCB Account Manager."
        );
        setTokenAuth(false);
      });
  };

  const handleDownload = async (fileName) => {
    setSnackbar({
      open: true,
      message: `Preparing to download ${fileName.slice(16)}. Please wait!`,
      severity: "info",
    });

    DownloadFile({ filename: fileName })
      .then((response) => response.json())
      .then(async (jsonData) => {
        if (jsonData.status_code !== 200) {
          setSnackbar({
            open: true,
            message: `Error in downloading file ${fileName.slice(16)}`,
            severity: "error",
          });
          return;
        }
        const fileUrl = jsonData.sasurl;
        if (fileUrl && fileUrl.startsWith("http")) {
          const response = await fetch(fileUrl, {
            method: "GET",
            headers: { "Cache-Control": "no-cache" },
          });

          if (!response.ok) {
            throw new Error(`Failed to fetch the file: ${response.statusText}`);
          }

          const blob = await response.blob();

          const downloadLink = document.createElement("a");
          const fileNames = fileName.slice(16) || "download.pdf";
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.download = fileNames;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(downloadLink.href);

          setSnackbar({
            open: true,
            message: `${fileNames} downloaded successfully!`,
            severity: "success",
          });
        }
      })
      .catch((error) => {
        setSnackbar({
          open: true,
          message: `Error in downloading file ${fileName.slice(16)}`,
          severity: "error",
        });
      });
  };

  const columns = [
    { field: "Date", headerName: "Date", flex: 1, sortable: false },
    { field: "Company", headerName: "Company", flex: 1.5, sortable: false },
    { field: "Type", headerName: "Type", flex: 1, sortable: false },
    { field: "OrderId", headerName: "Order ID", flex: 1, sortable: false },
    {
      field: "FileName",
      headerName: "FileName",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <button
          onClick={() => handleDownload(params.row.FileName)}
          className={styles.linkButton}
          style={{
            background: "none",
            border: "none",
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
            whiteSpace: "normal",
            wordBreak: "break-word",
            textAlign: "left",
          }}
        >
          {params.row.FileName.slice(16)}
        </button>
      ),
    },
  ];

  return (
    <div className={styles.transactionMain}>
      {tokenAuth && (
        <div className={styles.workSection} data-cy="Dates">
          <FormControl variant="outlined" style={{ width: "300px" }}>
            <InputLabel id="company-select-label">Select Companies</InputLabel>
            <Select
              labelId="company-select-label"
              id="company-select"
              multiple
              value={selectedKeys}
              onChange={(event) =>
                setSelectedKeys(event.target.value as string[])
              }
              MenuProps={MenuProps}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return "Select Companies";
                }
                return options
                  .filter((option) => selected.includes(option.account_id))
                  .map((option) => option.company)
                  .join(", ");
              }}
              label="Select Companies"
              data-cy="Select Companies"
            >
              {options.map((option) => (
                <MenuItem key={option.account_id} value={option.account_id}>
                  <Checkbox
                    checked={selectedKeys.indexOf(option.account_id) > -1}
                  />
                  <ListItemText primary={option.company} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              minDate={threeMonthsAgo}
              maxDate={today}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              minDate={threeMonthsAgo}
              maxDate={today}
            />
          </LocalizationProvider>

          <Button onClick={fetchTableData} className="defaultBtn">
            Fetch Data
          </Button>
        </div>
      )}
      {error && (
        <div className={styles.errorMessage}>
          <p className="error">{error}</p>
        </div>
      )}
      {tableData.length === 0 ? (
        tokenAuth ? (
          <div className={styles.fileTable}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                fontSize: "16px",
                fontWeight: "bold",
                color: "var(--secondary)",
              }}
            >
              No data available for this selection.
            </Box>
          </div>
        ) : (
          <div className={styles.fileTable}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                fontSize: "16px",
                fontWeight: "bold",
                color: "var(--error)",
              }}
            >
              {invalidMsg}
            </Box>
          </div>
        )
      ) : (
        <div className={styles.fileTable}>
          <DataGrid
            rows={fetching ? [] : tableData}
            columns={columns}
            loading={fetching}
            disableRowSelectionOnClick
            pagination
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            getRowHeight={() => "auto"}
            getRowSpacing={(params) => ({
              top: 4,
              bottom: 4,
            })}
            sx={{
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "var(--secondary)",
                color: "white",
              },
              "& .MuiDataGrid-cell": {
                whiteSpace: "normal",
                wordBreak: "break-word",
                lineHeight: 1.5,
                display: "block",
                padding: "8px 16px",
              },
              "& .MuiDataGrid-row": {
                alignItems: "flex-start",
                borderBottom: "1px solid #ddd",
              },
              "& .MuiTablePagination-toolbar": {
                display: "flex",
                alignItems: "flex-start",
              },
              "& .MuiDataGrid-menuIcon": {
                display: "none",
              },
              "& .MuiDataGrid-filler": {
                display: "none",
              },
              "& .MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "& .MuiDataGrid-scrollbarFiller--header": {
                backgroundColor: "var(--secondary)",
              },
              "& .MuiTablePagination-displayedRows": {
                marginTop: "10px",
              },
              "& .MuiTablePagination-input": {
                border: "1px solid black",
                marginTop: "7px",
              },
              "& .MuiTablePagination-selectLabel": {
                marginTop: "10px",
              },
            }}
          />
        </div>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
