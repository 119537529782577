import { Stack, StackItem, Text } from "@fluentui/react";
import React, { useContext } from "react";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import { ChatHistoryListItemGroups } from "./ChatHistoryListItem";
import { Conversation } from "../../../api/models";

interface ChatHistoryListProps {
  setDefaultQues: (val: string) => void;
}

export interface GroupedChatHistory {
  month: string;
  entries: Conversation[];
  position?: string;
}

const groupByMonth = (entries: Conversation[]) => {
  const groups: GroupedChatHistory[] = [
    { month: "PINNED CHAT (MAX 3)", entries: [] },
    { month: "PREVIOUS 30 DAYS", entries: [] },
  ];
  const currentDate = new Date();

  entries.forEach((entry) => {
    if (entry.pinned && entry.pinned === true) {
      groups[0].entries.push(entry);
    } else {
      const date = new Date(entry.date);
      const daysDifference =
        (currentDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24);
      const monthYear = date.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      const existingGroup = groups.find(
        (group) => group.month === monthYear.toUpperCase()
      );

      if (daysDifference <= 30) {
        groups[1].entries.push(entry);
      } else {
        if (existingGroup) {
          existingGroup.entries.push(entry);
        } else {
          groups.push({ month: monthYear.toUpperCase(), entries: [entry] });
        }
      }
    }
  });

  groups.sort((a, b) => {
    // Ensure the first group remains at the top if it has more than one entry
    if (groups[0].entries.length > 0) {
      if (a === groups[0]) return -1; // Keep 'a' at the top
      if (b === groups[0]) return 1; // Move 'b' below
    }

    // Check if either group has no entries and handle it
    if (a.entries.length === 0 && b.entries.length === 0) {
      return 0; // No change in order
    } else if (a.entries.length === 0) {
      return 1; // Move 'a' to a higher index (bottom)
    } else if (b.entries.length === 0) {
      return -1; // Move 'b' to a higher index (bottom)
    }

    // Sort based on the date of the first entry in descending order
    const dateA = new Date(a.entries[0].date);
    const dateB = new Date(b.entries[0].date);
    return dateB.getTime() - dateA.getTime();
  });

  groups.forEach((group) => {
    group.entries.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB.getTime() - dateA.getTime();
    });
  });

  return groups;
};
const ChatHistoryList: React.FC<ChatHistoryListProps> = ({
  setDefaultQues,
}) => {
  const appStateContext = useContext(AppStateContext);
  const chatHistory = appStateContext?.state.chatHistory;

  React.useEffect(() => {}, [appStateContext?.state.chatHistory]);

  let groupedChatHistory;
  if (chatHistory && chatHistory.length > 0) {
    groupedChatHistory = groupByMonth(chatHistory);
  } else {
    return (
      <Stack
        horizontal
        horizontalAlign="center"
        verticalAlign="center"
        style={{ width: "100%", marginTop: 10 }}
      >
        <StackItem>
          <Text
            style={{ alignSelf: "center", fontWeight: "400", fontSize: 14 }}
          >
            <span>No chat history.</span>
          </Text>
        </StackItem>
      </Stack>
    );
  }

  return (
    <ChatHistoryListItemGroups
      groupedChatHistory={groupedChatHistory}
      setDefaultQues={setDefaultQues}
    />
  );
};

export default ChatHistoryList;
