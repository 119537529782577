export type AskResponse = {
  answer: string;
  citations: Citation[];
  error?: string;
  message_id?: string;
  feedback?: Feedback;
  language?: string;
};

export type Citation = {
  content: string;
  id: string;
  title: string | null;
  filepath: string | null;
  url: string | null;
  metadata: string | null;
  chunk_id: string | null;
  reindex_id: string | null;
  documenturl?: string | null;
};

export type ToolMessageContent = {
  citations: Citation[];
  intent: string;
};

export type ChatMessage = {
  id: string;
  role: string;
  content: string;
  end_turn?: boolean;
  date: string;
  feedback?: Feedback;
  language?: string;
  summary?: string;
  isEdited?: boolean;
  isRegenerated?: boolean;
};
export type DefaultChatMessage = {
  id: string;
  role: string;
  content: string;
  end_turn?: boolean;
  date: string;
  feedback?: Feedback;
  language?: string;
  summary?: string;
  isEdited?: boolean;
  isRegenerated?: boolean;
};

export type Conversation = {
  id: string;
  title: string;
  messages: ChatMessage[];
  date: string;
  summary?: string;
  pinned?: boolean;
};

export enum ChatCompletionType {
  ChatCompletion = "chat.completion",
  ChatCompletionChunk = "chat.completion.chunk",
}

export type ChatResponseChoice = {
  messages: ChatMessage[];
};

export type ChatResponse = {
  id: string;
  language: string;
  created: number;
  object: ChatCompletionType;
  choices: ChatResponseChoice[];
  history_metadata: {
    conversation_id: string;
    title: string;
    date: string;
  };
  error?: any;
  isEdited?: boolean;
  isRegenerated?: boolean;
};

export type ConversationRequest = {
  messages: ChatMessage[];
  cancel_flag?: string;
};

export type UserInfo = {
  access_token: string;
  expires_on: string;
  id_token: string;
  provider_name: string;
  user_claims: any[];
  user_id: string;
};

export enum CosmosDBStatus {
  NotConfigured = "CosmosDB is not configured",
  NotWorking = "CosmosDB is not working",
  Working = "CosmosDB is configured and working",
}

export type CosmosDBHealth = {
  cosmosDB: boolean;
  status: string;
};

export enum ChatHistoryLoadingState {
  Loading = "loading",
  Success = "success",
  Fail = "fail",
  NotStarted = "notStarted",
}

export type ErrorMessage = {
  title: string;
  subtitle: string;
};

export type FrontendSettings = {
  auth_enabled?: string | null;
  feedback_enabled?: string | null;
};

export enum Feedback {
  WrongQuestionAddress = "Did not address my question",
  CitationIncorrect = "Citations are missing or incorrect",
  Outdated = "Outdated Response",
  IncorrectFact = "Factually incorrect",
  Irrelevant = "Irrelevant to my query",
  TechOrFormatIssue = "Technical or formatting issue",
  Difficult = "Difficult to understand",
  Neutral = "neutral",
  Positive = "positive",
  Negative = "negative",
  MissingCitation = "missing_citation",
  WrongCitation = "wrong_citation",
  OutOfScope = "out_of_scope",
  InaccurateOrIrrelevant = "inaccurate_or_irrelevant",
  OtherUnhelpful = "other_unhelpful",
  HateSpeech = "hate_speech",
  Violent = "violent",
  Sexual = "sexual",
  Manipulative = "manipulative",
  OtherHarmful = "other_harmlful",
}

export enum AuthMode {
  ReportingHub = "ReportingHub",
  MSAL = "MSAL",
}

export type UserLoginDetails = {
  user_email: string;
  user_name: string;
  provider: string;
};

export class Question {
  prompt: string;
  summary: string;
  title: string;
  constructor(title: string, prompt: string, summary: string) {
    this.prompt = prompt;
    this.summary = summary;
    this.title = title;
  }
}
export type ShareModal = {
  conversation_id: string | undefined;
  operation?: string;
  success?: string;
  new_conversation_id?: string;
};

export type UserProfileDetails = {
  full_name: string;
  // areas_of_interest: string;
  customized_prompting: string;
  role: string;
  gen_areas_of_interest: string;
  id: string;
  industry: string[];
  location: string;
  // work_location: string;
  company: string;
  usermail: string;
  personalization: boolean;
  username: string;
};
