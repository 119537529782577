import React, { useReducer, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import styles from "./Comparison.module.css";
import { getDocumentDetails, actionApi } from "../../../api";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import { Loader } from "../../CarbonAIChat/common/Common";
import { Stack } from "@fluentui/react";
import { comaparisonData } from "../../../../cypress/tests/constants";

const initialState = {
  tableData: [],
  fetching: true,
  showModal: false,
  modalMsg: "",
  modalContent: null,
  showLoader: true,
  modalType: "basic",
  actionPayload: {
    action: "",
    doc_hash_id: "",
    docName: "",
    file_hash_id: "",
    fileName: "",
  },
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_TABLE_DATA":
      return { ...state, tableData: action.payload, fetching: false };
    case "SET_ACTION_PAYLOAD":
      return { ...state, actionPayload: action.payload };
    case "SET_FETCHING":
      return { ...state, fetching: action.payload };
    case "SHOW_MODAL":
      return {
        ...state,
        showModal: true,
        modalMsg: action.payload.msg,
        modalContent: action.payload.content,
        modalType: action.payload.modalType,
      };
    case "HIDE_MODAL":
      return {
        ...state,
        showModal: false,
        modalMsg: "",
        modalContent: null,
        modalType: null,
      };
    case "LOADER_STATE":
      return { ...state, showLoader: action.payload };
    default:
      return state;
  }
}

const Comparison: React.FC = () => {
  const appStateContext = useContext(AppStateContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchData = () => {
    dispatch({ type: "SET_FETCHING", payload: true });
    getDocumentDetails()
      .then((response) => response.json())
      .then((jsonData) => {
        dispatch({ type: "LOADER_STATE", payload: false });
        dispatch({ type: "SET_TABLE_DATA", payload: jsonData });
      })
      .catch((error) => {
        dispatch({ type: "LOADER_STATE", payload: false });
        console.error("Error fetching data", error);
        dispatch({ type: "SET_TABLE_DATA", payload: [] });
      });
  };

  const handleActionClick = (
    action,
    docName,
    docHash,
    fileName = "",
    fileHash = ""
  ) => {
    const value =
      action === "delete_document"
        ? "Delete Document"
        : action === "delete_file"
        ? "Replace File"
        : "Ignore File";
    dispatch({
      type: "SET_ACTION_PAYLOAD",
      payload: {
        action: action,
        docName: docName,
        doc_hash_id: docHash,
        fileName: fileName,
        file_hash_id: fileHash,
      },
    });
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        content: `Are you sure you want to ${value}?`,
        modalType: "confirmation",
      },
    });
  };
  const actionApiCall = () => {
    dispatch({
      type: "HIDE_MODAL",
    });
    dispatch({ type: "LOADER_STATE", payload: true });

    actionApi(state.actionPayload)
      .then((response) => response.json())
      .then((jsonData) => {
        dispatch({ type: "LOADER_STATE", payload: false });
        dispatch({
          type: "SHOW_MODAL",
          payload: {
            content: jsonData.message,
            modalType: "basic",
          },
        });

        fetchData();
      })
      .catch((error) => {
        console.error("Error fetching data", error);
        dispatch({ type: "LOADER_STATE", payload: false });
        dispatch({
          type: "SHOW_MODAL",
          payload: {
            content: "Facing some error while doing this action.",
            modalType: "basic",
          },
        });

        fetchData();
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Stack
        className={
          appStateContext?.state.isSmallScreen
            ? `form-group ${styles.mainSmallScreen}`
            : `form-group ${styles.main}`
        }
      >
        <Stack.Item className={styles.workSection}>
          <Stack>
            {state.tableData.length > 0 ? (
              state.tableData.map((item: any) => (
                <div key={item.contenthash} className={styles.collapseSection}>
                  <div
                    className={styles.collapseHeader}
                    onClick={(e) =>
                      e.currentTarget.nextElementSibling?.classList.toggle(
                        styles.hidden
                      )
                    }
                  >
                    <h5 className={styles.wordBreak}>{item.documentname}</h5>
                    <button
                      className={styles.deleteButton}
                      data-cy={`delete-doc-${item.contenthash}`}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleActionClick(
                          "delete_document",
                          item.documentname,
                          item.contenthash
                        );
                      }}
                    >
                      Delete Document
                    </button>
                  </div>
                  <div className={styles.fileTable}>
                    <table
                      data-cy={`table-${item.contenthash}`}
                      className={styles.table}
                    >
                      <thead>
                        <tr>
                          <th>Similar File Name</th>
                          <th>Similarity Score</th>
                          <th>Reason</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.similar_docs.map((file, index) => (
                          <tr
                            key={file.contenthash}
                            className={
                              index % 2 === 0 ? styles.trOdd : styles.trEven
                            }
                          >
                            <td data-cy={`file-${item.contenthash}`}>
                              {file.documentname}
                            </td>
                            <td>{file.score}</td>
                            <td>
                              <button
                                className={styles.linkButton}
                                data-cy={`reason-${file.contenthash}`}
                                onClick={() =>
                                  dispatch({
                                    type: "SHOW_MODAL",
                                    payload: {
                                      msg: "Reason Details",
                                      content: <p>{file.llm_diff}</p>,
                                      modalType: "basic",
                                    },
                                  })
                                }
                              >
                                View
                              </button>
                            </td>
                            <td>
                              <button
                                data-cy={`ignore-file-${file.contenthash}`}
                                className={styles.linkButton}
                                onClick={() =>
                                  handleActionClick(
                                    "ignore",
                                    item.documentname,
                                    item.contenthash,
                                    file.documentname,
                                    file.contenthash
                                  )
                                }
                              >
                                Ignore File
                              </button>
                              <br />
                              <button
                                data-cy={`delete-file-${file.contenthash}`}
                                className={styles.linkButton}
                                onClick={() =>
                                  handleActionClick(
                                    "delete_file",
                                    item.documentname,
                                    item.contenthash,
                                    file.documentname,
                                    file.contenthash
                                  )
                                }
                              >
                                Replace File
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.noData}>
                Congratulations!!! <br /> You don't have any pending reviews.
              </div>
            )}
          </Stack>
        </Stack.Item>
      </Stack>
      {state.showModal && (
        <Modal
          show={state.showModal}
          onHide={() => dispatch({ type: "HIDE_MODAL" })}
          centered
        >
          <Modal.Body>
            <b>{state.modalMsg}</b>
            {state.modalContent}
          </Modal.Body>
          <Modal.Footer>
            {state.modalType === "basic" && (
              <Button
                className={styles.popBtn}
                variant="secondary"
                onClick={() => dispatch({ type: "HIDE_MODAL" })}
              >
                Close
              </Button>
            )}
            {state.modalType === "confirmation" && (
              <>
                <Button
                  className={styles.popBtn}
                  variant="danger"
                  onClick={() => dispatch({ type: "HIDE_MODAL" })}
                >
                  No
                </Button>
                <Button
                  className={styles.yesBtn}
                  onClick={() => {
                    actionApiCall(); // Pass both docName and fileName
                  }}
                >
                  Yes
                </Button>
              </>
            )}
          </Modal.Footer>
        </Modal>
      )}
      {state.showLoader && (
        <Modal className="apiLoader" show={state.showLoader} centered>
          <Modal.Body>
            <Loader></Loader>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Comparison;
